













































































































































































































import api from "@/api/index"; //ABP API接口
import { Component, Vue } from "vue-property-decorator";
import { CustomerType, OrderMainDto, SelectListItem } from "@/api/appService";
import moment from "moment";
import getPdf from "@/utils/exportPDF";

@Component({
  name: "Invoice",
})
export default class Invoice extends Vue {
  dataId?: string;
  detail: OrderMainDto = {
    // fkReceivingAddressMapping:{} as CustomerReceivingAddressMappingDto
  };
  loading = true;
  orderReceivingMethodList = [] as SelectListItem[];
  detailList = [] as any[];

  subTotal = 0;

  created() {
    if (this.$route.params.id) {
      this.dataId = this.$route.params.id;
      this.fetchDetail();
    }
    this.fetchOrderReceivingMethodList();
  }

  // 获取详情
  async fetchDetail() {
    this.subTotal = 0;
    await api.orderMain.get({ id: this.dataId }).then((res) => {
      this.loading = false;
      this.detail = { ...res };
      this.detail.detailList?.map((item) => {
        this.$set(item, "total", item.price! * item.quantity!);
        this.subTotal += item.price! * item.quantity!;
      });
      // let arr = [
      //   {
      //     price: "NET",
      //     total: this.detail.productSkuTotalPrice,
      //   },
      //   {
      //     price: "GST",
      //     total: this.detail.taxesGST,
      //   },
      //   {
      //     price: "QST",
      //     total: this.detail.taxesQST,
      //   },
      //   {
      //     price: "Shipping & Handling",
      //     total: this.detail.tranExpenses,
      //   },
      //   {
      //     price: "TOTAL",
      //     total: this.detail.totalAmount,
      //   },
      // ] as any[];
      //添加空白行
      this.detailList = this.detail.detailList?.concat([
        {},
        {},
      ]) as any[];
    });
  }

  getCustomerTypeText() {
    if (this.detail.fkCustomer) {
      if (this.detail.fkCustomer.customerType === CustomerType.Enterprise) {
        return "Business";
      } else if (this.detail.fkCustomer.customerType === CustomerType.Member) {
        return "Membership";
      } else if (
        this.detail.fkCustomer.customerType === CustomerType.Ordinary
      ) {
        return "Person";
      }
    }
    return "";
  }

  async fetchOrderReceivingMethodList() {
    await api.enumService
      .getValues({ typeName: "OrderReceivingMethod" })
      .then((res) => {
        this.orderReceivingMethodList = res;
      });
  }

  getOrderReceivingMethodText(value: any) {
    let text = "";
    for (let i in this.orderReceivingMethodList) {
      if (this.orderReceivingMethodList[i].value === value) {
        text = this.orderReceivingMethodList[i].text!;
      }
    }
    return text;
  }

  formatDate(date: any) {
    return moment(String(date)).format("MM/DD/YYYY");
  }

  cellMerge({ row, column, rowIndex, columnIndex }: any) {
    if (columnIndex === 4) {
      const _row = this.detail.detailList?.length ?? 0;
      const _col = _row > 0 ? 1 : 0;
      return {
        rowspan: _row,
        colspan: _col,
      };
    }
  }

  handleDownLoad() {
    const downloadUrl = this.detail.generatedInvoicePath;
    window.open(downloadUrl, "_blank");
    // getPdf.downloadPDF(
    //   "#pdfPrint",
    //   ((this as any).$l.getLanguageText("invoice.order") as string) +
    //     this.detail.orderCode +
    //     ((this as any).$l.getLanguageText("invoice.bill") as string)
    // );
  }

  getSummaries(param: any) {
    //此处打印param可以看到有两项，一项是columns，一项是data，最后一列可以通过columns.length获取到。
    const { columns, data } = param;
    const len = columns.length;
    const sums = [] as any[];
    columns.forEach((column: any, index: any) => {
      //如果是第一列，则最后一行展示为“总计”两个字
      if (index === len - 2) {
        sums[index] = "NET";
        //如果是最后一列，索引为列数-1，则显示计算总和
      } else if (index === len - 1) {
        sums[index] = this.detail.productSkuTotalPrice;
      } else {
        sums[index] = "";
      }
    });
    return sums;
  }
}
